<template>
  <div class="edit-profile">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        color="darkLight"
        label="Cédula"
        v-model="item.dni"
        :autocomplete="'off'"
        :rules="dniRules"
      ></v-text-field>

      <v-text-field
        color="darkLight"
        label="Nombre"
        v-model="item.name"
        :autocomplete="'off'"
        :rules="requiredRules"
      ></v-text-field>

      <v-text-field
        color="darkLight"
        label="Apellido"
        v-model="item.lastName"
        :autocomplete="'off'"
        :rules="requiredRules"
      ></v-text-field>

      <v-text-field
        color="darkLight"
        label="Correo electrónico"
        v-model="item.email"
        :autocomplete="'off'"
        :rules="emailRules"
      ></v-text-field>

      <v-text-field
        color="darkLight"
        label="Teléfono"
        v-model="item.phone"
        :autocomplete="'off'"
        :rules="numberRules"
      ></v-text-field>

      <v-btn
        block
        color="darkLight"
        class="white--text"
        rounded
        @click="btnUpdateUser()"
      >
        Actualizar
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserServices from "../../../../core/services/user-services";
import UserStorage from "../../../../core/storage/user-storage";
import useValidate from "../../../../shared/validate/useValidate";

export default {
  name: "EditProfile",
  data: () => ({
    valid: false,
    item: {},
    requiredRules: [useValidate["required"]],
    dniRules: [useValidate["required"], useValidate["dni"]],
    emailRules: [useValidate["required"], useValidate["email"]],
    numberRules: [useValidate["phone"]],
  }),
  computed: {
    ...mapGetters(["getUserId"]),
  },
  created() {
    this.item = this.getUserId;
  },
  props: {
    photo: {
      type: String,
      default: "",
    },
  },
  methods: {
    ...mapActions(["getUserLogin"]),
    btnUpdateUser() {
      const { updateUser } = UserServices();
      const { addUser } = UserStorage();
      this.item = { ...this.item, photo: this.photo };

      updateUser(this.item.id, this.item).then((resp) => {
        const response = resp.data;
        if (response.code === 201) {
          addUser(response.data);
          this.getUserLogin();
          this.$toast.success("Los datos se actualizaron correctamente");
        }
      });
    },
  },
};
</script>

<style lang="scss">
.edit-profile {
  padding: 10px 20px 20px;
}
</style>
