<template>
  <div class="profile">
    <div class="content">
      <div class="image">
        <input
          id="photoProfile"
          type="file"
          accept="image/jpeg,image/png,.jpeg,.jpg,.png"
          style="display: none"
          @change="processWebImage($event)"
        />
        <div class="photo">
          <label for="photoProfile">
            <img :src="photoEdit | imageProfile" alt="profile" />
            <div class="camera">
              <v-icon>mdi-camera</v-icon>
            </div>
          </label>
        </div>
        <div class="text">
          <p>{{ user.name }} {{ user.lastName }}</p>
        </div>
      </div>

      <v-divider></v-divider>
      <EditProfile :photo="photoEdit" />
    </div>

    <CustomCrop
      :dialog.sync="modalCrop"
      :imgSrc.sync="photo"
      @onChange="onChangeCrop($event)"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import EditProfile from "./edit-profile.vue";
import CustomCrop from "../../../../shared/components/custom-crop/CustomCrop.vue";

export default {
  name: "Profile",
  data: () => ({
    modalCrop: false,
    photo: "",
    photoEdit: "",
  }),
  components: {
    EditProfile,
    CustomCrop,
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    this.photoEdit = this.user.photo;
  },
  methods: {
    processWebImage(event) {
      const reader = new FileReader();
      reader.onload = async (readerEvent) => {
        const image64 = readerEvent.target.result;
        this.modalCrop = true;
        this.photo = image64;
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    onChangeCrop(event) {
      this.photoEdit = event;
    },
  },
};
</script>

<style lang="scss">
.profile {
  .content {
    .image {
      background: linear-gradient(
        153deg,
        var(--color-primary) 20%,
        var(--color-third) 100%
      );
      padding-top: 20px;
      padding-bottom: 1px;
      .photo {
        width: 100%;
        display: flex;
        justify-content: center;

        label {
          display: block;
          width: 150px;
          position: relative;
          text-align: center;

          img {
            width: 120px;
            border-radius: 100%;
            border: 4px solid #fff;
            box-shadow: inset 0 1.5px 3px 0 rgb(0 0 0 / 15%),
              0 1.5px 3px 0 rgb(0 0 0 / 15%);
            box-sizing: content-box;
          }

          .camera {
            width: 25px;
            height: 25px;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            position: absolute;
            right: 25px;
            margin-top: -35px;
            border: solid 1px rgb(0 0 0 / 15%);

            i {
              color: var(--color-second);
              font-size: 16px;
            }
          }
        }
      }
    }

    .text {
      text-align: center;
      margin-top: 8px;
      font-size: 20px;
      font-weight: 600;
      text-transform: uppercase;
      color: var(--color-white);
    }
  }
}
</style>
